const defaultFormatPriceOptions = {
  pattern: `# !`,
  negativePattern: `-# !`,
  separator: ` `,
  decimal: `,`,
  symbol: `€`,
  formatWithSymbol: true,
};

export function formatPrice(price, options) {
  if (typeof price !== "number") {
    return;
  }

  return currency(
    price,
    Object.assign(defaultFormatPriceOptions, options)
  ).format();
}

export function filterPrice(price) {
  const { currencySymbol } = useCurrency();

  return formatPrice(unref(price), { symbol: currencySymbol.value });
}

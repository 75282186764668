export function useCurrency() {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();

  const { sessionContext } = useShopware();

  const currency = computed(() => {
    return sessionContext.value?.currency;
  });

  const currencySymbol = computed(() => currency.value?.symbol || "");

  return {
    currencySymbol,
    currency,
  };
}
